import React, { useEffect, useState, useRef } from 'react';
import { SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import {
  GoogleMap,
  GoogleMarkerClusterer,
  Marker,
  InfoBox,
} from '@react-google-maps/api';
import { CentrePopup } from 'components/Map/Centre';

const Map = ({ loading, data, results, setCentre }) => {
  const mapRef = useRef(null);
  const mapBoundsRef = useRef({
    lat: -25.2744,
    lng: 133.7751,
  });

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = centre => {
    if (centre.id === activeMarker) {
      return;
    }

    setActiveMarker(centre.id);

    mapRef.current.panTo({
      lat: centre.latitude,
      lng: centre.longitude,
    });
  };

  useEffect(() => {
    if (
      !mapRef?.current ||
      loading ||
      !data?.centreSearch?.centres?.length ||
      !data?.centreSearch?.showResultsList
    ) {
      return;
    }

    const bounds = new window.google.maps.LatLngBounds();

    setActiveMarker(null);

    if (results) {
      bounds.extend(
        new window.google.maps.LatLng(results.latitude, results.longitude)
      );
    }

    data?.centreSearch?.centres?.slice(0, 19)?.forEach(centre => {
      bounds.extend(
        new window.google.maps.LatLng(centre.latitude, centre.longitude)
      );
    });

    // Uses the current map position if new search returns no centres
    mapBoundsRef.current = bounds.getCenter();

    mapRef.current.fitBounds(bounds);
    mapRef.current.panToBounds(bounds);
  }, [mapRef?.current, loading, data, results]);

  const onLoad = map => {
    mapRef.current = map;
  };

  const clusterOptions = {
    algorithm: new SuperClusterAlgorithm({
      maxZoom: 10,
    }),
    renderer: {
      render: ({ count, position }) =>
        new google.maps.Marker({
          label: {
            text: String(count),
            color: 'white',
            fontSize: '12px',
            fontWeight: 'bold',
          },
          position,
          // adjust zIndex to be above other markers
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        }),
    },
  };

  return (
    <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: '100%',
      }}
      center={mapBoundsRef?.current}
      zoom={5}
      onLoad={onLoad}
    >
      {mapRef?.current && data?.centreSearch?.centres?.length && (
        <GoogleMarkerClusterer options={clusterOptions}>
          {clusterer =>
            data?.centreSearch?.centres?.map(centre => (
              <Marker
                clusterer={clusterer}
                key={centre.id}
                position={{
                  lat: centre.latitude,
                  lng: centre.longitude,
                }}
                onClick={() => handleActiveMarker(centre)}
              >
                {activeMarker === centre.id ? (
                  <InfoBox
                    options={{
                      boxStyle: { backgroundColor: '#fff' },
                      // isHidden: activeMarker !== centre.id,
                      // visible: activeMarker === centre.id,
                      closeBoxURL: '',
                      enableEventPropagation: true,
                    }}
                  >
                    <div
                      style={{
                        padding: '16px 12px',
                        width: '350px',
                      }}
                    >
                      <CentrePopup
                        centre={centre}
                        setCentre={setCentre}
                        searchDetails={results}
                        onClose={() => setActiveMarker(null)}
                      />
                    </div>
                  </InfoBox>
                ) : null}
              </Marker>
            ))
          }
        </GoogleMarkerClusterer>
      )}
    </GoogleMap>
  );
};

export default React.memo(Map);
