import { DateTime } from 'luxon';

const today = DateTime.local();
const tomorrow = today.plus({ days: 1 });
const currentDay = today.toFormat('EEEE');

export const appointmentsAvailable = centre => {
  if (!centre?.nextAppointment?.date) {
    return null;
  }

  const nextAppointment = DateTime.fromISO(centre?.nextAppointment?.date);
  let nextAppointmentDay = nextAppointment?.isValid
    ? `on ${nextAppointment.toFormat('cccc')}`
    : null;

  if (nextAppointment?.hasSame(today, 'day')) {
    nextAppointmentDay = 'today';
  } else if (nextAppointment?.hasSame(tomorrow, 'day')) {
    nextAppointmentDay = 'tomorrow';
  }

  return centre?.nextAppointment?.number && nextAppointmentDay
    ? `${centre?.nextAppointment?.number} appointments available ${nextAppointmentDay}`
    : '';
};

export const openingHours = centre => {
  const currentHours = centre?.hours?.find(
    h => (h.day || '').toLowerCase() === currentDay.toLowerCase()
  );
  const hasHours = !!currentHours;

  return hasHours ? currentHours.message : '';
};
