import { Box, useThemeUI } from 'theme-ui';

const CloseIcon = ({ width, color, sx }) => {
  const { theme } = useThemeUI();

  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      fill="none"
      sx={{
        minWidth: width || '22px',
        width: width || '22px',
        pointerEvents: 'none',
        ...sx,
      }}
    >
      <path
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
        stroke="#255EC1"
        strokeWidth="2"
      />
      <path
        d="M9 9L15 15M15 9L9 15"
        stroke="#255EC1"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </Box>
  );
};

export default CloseIcon;
